export enum REFERER_TYPES {
  JOB = 'job',
  UNIVERSAL_JOB = 'universal-job',
  HOME_PAGE = 'home-page',
  ALL_JOBS = 'all-jobs',
  HOME_PAGE_ENT = 'ent-home-page',
  ALL_JOBS_ENT = 'ent-all-jobs',
  INTERNAL_JOB_PLATFORM = 'internal-job-platform',
  INTERNAL_JOB_PLATFORM_APPLY = 'internal-job-platform-apply'
}
